import { axios } from '../../axios';
import { getCommonHeaders } from '../../utils/headers';
import { ApiRootResponse } from './types';

const ENDPOINT = '';

export async function getRoot(
  options: {
    signal?: AbortSignal;
    authToken?: string | null;
  } = {},
): Promise<ApiRootResponse> {
  const { data } = await axios.get(ENDPOINT, {
    signal: options?.signal,
    headers: getCommonHeaders({ authToken: options.authToken }),
  });

  return data;
}
