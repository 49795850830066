export default {
  en: {
    translation: {
      common: {
        back: 'Back',
        default_error_title: 'Error',
        dialog: {
          close_button: 'Close',
        },
      },
      actions: {
        default_submit_button: 'Submit',
        default_submit_button_pending: 'Submitting...',
        'action-form-loader': {
          error: {
            reset: 'Try again',
          },
        },
        stepped: {
          prev: 'Previous',
          next: 'Next',
          submit: 'Submit',
          invalid_step: 'Invalid step',
        },
      },
      shop_per_person: 'per person',
      sidebar_welcome_anonymous: 'Welcome',
      signing_out: 'Signing out...',
      sign_in: 'Sign in',
      sign_out: 'Sign out',
      account: {
        header: 'My Account',
        error: {
          reset: 'Try again',
        },
      },
      home: {
        error: {
          reset: 'Try again',
        },
      },
      headerbar: {
        error: {
          loading_description: 'Error loading header bar',
          reset: 'Try again',
        },
      },
      sidebar: {
        attractions: 'Attractions',
        account: 'My Account',
        change_language: 'Change language',
        cookie_policy: 'Cookie policy',
        shop: 'Shop',
        welcome_anonymous: 'Welcome',
        error: {
          loading_description: 'Error loading sidebar',
          reset: 'Try again',
          shop: {
            reset: 'Try again',
          },
        },
        get_the_app: 'Six Flags App',
        get_the_app_link: 'https://www.sixflags.com/national/app-download',
      },
      attractions_list: {
        error: {
          loading_description: 'Error loading attractions list',
          reset: 'Try again',
        },
        header: 'All rides',
        item: {
          image: {
            alt_text: 'Image of {{name}}',
          },
        },
      },
      attractions_details: {
        error: {
          loading_description: 'Error loading attraction details',
          reset: 'Try again',
        },
      },
      cookie_policy: {
        title: 'Cookie Policy',
        description:
          "Our site needs to use functional cookies to ensure that you have the best experience possible. By continuing to browse the site you consent to having cookies placed on your device. Don't worry though, these are harmless and ensure we can keep a track of your reserved rides and continue to provide an excellent service to you. Not allowing cookies on this website means you won't be able to use the system. <br><br> We use Hotjar in order to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices. This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf.",
        required_cookies_title: 'Functional cookies',
        optional_cookies_title: 'Optional cookies',
        required_cookies_description:
          "Our site needs to use functional cookies to ensure that we can keep a track of your reserved rides and continue to provide an excellent service to you. Not allowing cookies on this website means you won't be able to use the system.",
        optional_cookies_description:
          'We use tracking cookies to better understand our users’ needs and to optimize this service and experience.',
        consent: 'Do you consent to our Cookie Policy?',
        link: 'Click here to see more',
        close: 'Close',
        accept_button: 'Accept All',
        reject_button: 'Reject Optional',
        error: {
          loading_description: 'There was an error loading the cookie policy!',
          reset: 'Try again',
        },
        cookie_policypage: {
          error: {
            loading_description: 'Error loading cookie policy page',
            reset: 'Try again',
          },
        },
      },
      onboarding_overlay: {
        title: 'Start using THE FLASH PASS!',
        shop: {
          primary: 'Buy THE FLASH PASS',
          secondary: 'I want to buy THE FLASH PASS',
        },
        ticket: {
          primary: 'Use my THE FLASH PASS',
          secondary: 'Already purchased? Activate here',
        },
        membership: {
          primary: 'Use my pass or benefits',
          secondary: 'Activate Season Pass or Membership Benefits',
        },
        error: {
          loading_description: 'Error loading onboarding overlay',
          reset: 'Try again',
        },
      },
      onboarding: {
        membership: {
          signin: {
            error: {
              loading_description: 'Error loading sign in page',
              reset: 'Try again',
            },
          },
        },
        ticket: {
          claim: {
            error: {
              loading_description: 'Error loading ticket claim page',
              reset: 'Try again',
            },
          },
          signin: {
            error: {
              loading_description: 'Error loading sign in page',
              reset: 'Try again',
            },
          },
        },
      },
      flows: {
        onboarding: {
          next_step: 'Next',
          ticket: {
            claim: {
              no_action: 'No claim action available',
            },
          },
          membership: {
            claim: {
              no_action: 'No claim action available',
            },
          },
        },
      },
      developer: {
        heading: 'Developer',
        error: {
          loading_description: 'Error loading Developer page',
          reset: 'Try again',
        },
      },
      reservations: {
        error: {
          reset: 'Try again',
        },
      },
      basket: {
        basket_invoice: {
          subtotal: 'Subtotal',
          tax: 'Tax',
          total: 'Total',
        },
        basket_details: {
          edit: 'Edit',
          perPerson: 'per person',
        },
      },
      shop: {
        error: {
          reset: 'Try again',
        },
      },
      sitebanner: {
        error: {
          loading_description: 'Error loading site banner',
          reset: 'Try again',
        },
      },
    },
  },
};
