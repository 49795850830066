import { ApiLinks, ApiResourceAction } from '@/api/types';
import { Guid } from '@/types/utility-types';

/**
 * Looking for shop API Types ?
 * check out `src/api/types/shop.ts`
 */

export type TPrice = {
  pre_tax: number;
  pre_tax_string: string;
  tax: number;
  tax_string: string;
  post_tax: number;
  post_tax_string: string;
};

export type TShopItemType = 'all' | 'service' | 'addons' | 'oneshot' | 'bundle';

export const ShopItemTypes = [
  'all',
  'service',
  'addons',
  'oneshot',
  'bundle',
] as const;

export const ShopItemStates = ['available', 'purchase', 'upgrade'] as const;

export const ItemStates = ['available'] as const;

export type TShopItem = {
  actions: ApiResourceAction[];
  description: string;
  links: ApiLinks;
  id: Guid;
  isActive: boolean;
  name: string;
  price: TPrice;
  state: (typeof ItemStates)[number];
  tagline: string;
  tags: string[];
  type: TShopItemType;
};
