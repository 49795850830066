/**
 * Keep any Typeguards we implement in here.
 */

/**
 * Check if a value is a string.
 *
 * @param value - The value to check.
 * @returns boolean - Whether the value is a string or not.
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

/**
 * Check if a value is boolean.
 *
 * @param value - The value to check.
 * @returns boolean - Whether the value is a boolean or not.
 */
export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

/**
 * Check if a value is a number.
 *
 * @param value - The value to check.
 * @returns boolean - Whether the value is a number or not.
 */
export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

/**
 *
 * @param value
 * @param union
 * @returns boolean - Whether the value is within the union or not.
 */
export function isWithinUnion<T extends string>(
  value: string | unknown,
  union: T[],
): value is T {
  if (typeof value !== 'string') return false;
  return union.includes(value as T);
}
