// The import order of tailwind and our index css below is important!
// eslint-disable-next-line simple-import-sort/imports
import '@pebl/tailwind/css';

import '@/styles/index.css';

import { queryClient } from '@/api/query-client';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { DynamicTheming } from '@/components/dynamic-theming';
import { Error404 } from '@/components/error-404';
import i18n from '@/i18n/config';
import { RuntimeLocalisation } from '@/i18n/runtime-localisation';
import { Toaster } from '@pebl/ui';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { Layout } from './layout';
import routes from './routing';

/**
 * Primary entrypoint for the New Guest Website (AKA Wrapper).
 */

async function enableMocking() {
  // We ALWAYS disable mocking in any non development environment.
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  // API Mocking is opt-in via an environment variable.
  // So people never use it or are confused by it unless they explicitly
  // want to use mocking for development purposes.
  // Set VITE_MSW_MOCKING_ENABLED=true in your .env.local file to enable mocking.
  if (import.meta.env.VITE_MSW_MOCKING_ENABLED !== 'true') {
    return;
  }

  const { worker } = await import('../../api/mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({ onUnhandledRequest: 'bypass' });
}

enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root')!);

  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <DynamicTheming prefix="themes/" />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <I18nextProvider i18n={i18n}>
            <RuntimeLocalisation />
            <Toaster>
              <AppRouter />
            </Toaster>
          </I18nextProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </React.StrictMode>,
  );
});

// eslint-disable-next-line react-refresh/only-export-components
function AppRouter() {
  // const router = createBrowserRouter([
  const router = createHashRouter([
    {
      element: <Layout />,
      errorElement: <Error404 />,
      children: routes,
    },
  ]);

  return <RouterProvider router={router} />;
}
