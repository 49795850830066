import Axios, { InternalAxiosRequestConfig } from 'axios';

// The Base URL is injected in from CloudFront via the configuration.js file
// which sets the window.globalQngConfig object, containing 'apiUri'

function requestInterceptor(config: InternalAxiosRequestConfig) {
  // NOTE: We can do any global request augmentation here
  return config;
}

function requestErrorInterceptor(error: unknown) {
  // NOTE: We can do any global request error handling here
  return Promise.reject(error);
}

export const axios = Axios.create({
  baseURL: `https://${window.globalQngConfig.apiUri}`,
});

axios.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
