import { CartIcon } from '@pebl/ui';

import RoutePathConstants from '@/entrypoints/guest-website/routing/route-path-constants';
import { TranslationComponentProps } from '@/types/translation';

import { SideBarItem } from '../side-bar-item';

type ShopItemViewProps = {
  /**
   * Enable the loading state of this component.
   */
  loading?: boolean;
} & TranslationComponentProps;

export function ShopItemView({ loading, t }: ShopItemViewProps) {
  return (
    <SideBarItem
      loading={loading}
      name={t('sidebar.shop')}
      className="side-bar-shop"
      icon={<CartIcon className="size-6" />}
      route={RoutePathConstants.SHOP.link({ type: 'service' })}
    />
  );
}
