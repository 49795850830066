import React from 'react';
import { useParams } from 'react-router-dom';

import { AnimatedRoute } from '../animated-route';

const TagViewer = React.lazy(
  () => import('@/features/actions/components/tag-viewer'),
);

type TagRouteParams = {
  tagId: string;
};

export function TagRoute() {
  const { tagId } = useParams<TagRouteParams>();

  if (!tagId) {
    // TODO: Assess how we actually want to handle this
    throw new Error('Missing required route params');
  }

  return (
    <AnimatedRoute animationKey="sign-in">
      <TagViewer tagId={tagId} />
    </AnimatedRoute>
  );
}
