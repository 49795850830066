import {
  InfoIcon,
  LanguageIcon,
  MobilePhoneIcon,
  PersonIcon,
  StarIcon,
} from '@pebl/ui';

import { Divider } from '@/components/divider';
import RoutePathConstants from '@/entrypoints/guest-website/routing/route-path-constants';
import { TranslationComponentProps } from '@/types/translation';

import { ShopItem } from '../shop-item';
import { SideBarItem } from '../side-bar-item';
import { SignInOutView } from '../sign-in-out-item';

type SideBarViewProps = {
  loading?: boolean;
  isUserSignedIn?: boolean;
} & TranslationComponentProps;

export function SideBarView({ isUserSignedIn, loading, t }: SideBarViewProps) {
  return (
    <div className="qng-side-bar">
      <div className="qng-side-bar-main">
        <SideBarItem
          className="qng-side-bar-attractions"
          loading={loading}
          name={t('sidebar.attractions')}
          icon={<StarIcon className="size-6" />}
          route={RoutePathConstants.ATTRACTIONS.link()}
        />

        <ShopItem loading={loading} />

        {isUserSignedIn && (
          <SideBarItem
            className="qng-side-bar-account"
            loading={loading}
            name={t('sidebar.account')}
            icon={<PersonIcon className="size-6" />}
            route={RoutePathConstants.ACCOUNT.link()}
          />
        )}
      </div>

      <Divider />

      <div className="qng-side-bar-other">
        <SignInOutView isSignedIn={isUserSignedIn} loading={loading} t={t} />

        <SideBarItem
          className="qng-side-bar-change-language"
          loading={loading}
          name={t('sidebar.change_language')}
          icon={<LanguageIcon className="size-6" />}
          route={RoutePathConstants.CHANGE_LANGUAGE.link()}
        />

        {t('sidebar.get_the_app') && (
          <SideBarItem
            className="qng-side-bar-get-the-app"
            loading={loading}
            name={t('sidebar.get_the_app')}
            icon={<MobilePhoneIcon className="size-6" />}
            route={t('sidebar.get_the_app_link')}
          />
        )}

        <SideBarItem
          className="qng-side-bar-cookie-policy"
          loading={loading}
          name={t('sidebar.cookie_policy')}
          icon={<InfoIcon className="size-6" />}
          route={RoutePathConstants.COOKIE_POLICY.link()}
        />
      </div>
    </div>
  );
}
