import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSelectQngAuth } from '@/stores/selectors';
import { TranslationComponentProps } from '@/types/translation';

import { useGetRoot } from '../../../../common-queries/use-get-root';
import { Sidebar } from '../side-bar';
import { HeaderBarView } from './header-bar-view';

type HeaderBarDataProps = {} & TranslationComponentProps;

export function HeaderBarData({ t }: HeaderBarDataProps) {
  const { authToken, isAuthTokenAnonymous } = useSelectQngAuth();
  const { data } = useGetRoot({ authToken, isAuthTokenAnonymous });

  const [open, setOpen] = useState<boolean>(false);

  // A fairly horrible hack to close the sidebar when the route changes
  // https://github.com/radix-ui/primitives/issues/1301
  const { pathname } = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const title = data?.client_data?.['header'];
  const username = data?.data?.user;

  return (
    <HeaderBarView
      open={open}
      setOpen={setOpen}
      title={title ?? undefined}
      sidebarTitle={username}
      t={t}
    >
      <Sidebar />
    </HeaderBarView>
  );
}
