import { useEffect, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { setAuthToken } from '@/stores/qng-data-store';

import RoutePathConstants from '../route-path-constants';

export function SignOutRoute() {
  const { t } = useTranslation();
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      setAuthToken({ authToken: undefined, isTokenAnonymous: false });
    });
  }, []);

  if (isPending) {
    return <div>{t('signing_out')}</div>;
  }

  return <Navigate to={RoutePathConstants.HOME.link()} replace={true} />;
}
