import { Skeleton, Typography } from '@pebl/ui';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ApiResourceActionColor } from '@/api/types';

export interface SidebarItemData {
  name?: string;
  color?: ApiResourceActionColor;
  icon?: string | ReactNode;
  loading?: boolean;

  className?: string;

  route?: string; // TODO: Confirm what this is used for on the API

  component?: React.ElementType;
}

export function SideBarItem({
  className,
  component = 'div',
  icon,
  loading,
  name,
  route,
}: SidebarItemData) {
  const Component = component;
  return (
    <Component className={clsx('grid min-h-14', className)}>
      {loading ? (
        <div className="grid grid-cols-[max-content,1fr] items-center gap-4 p-2 pl-4">
          <Skeleton variant="small-circle" />
          <Skeleton variant="text" className="w-full" />
        </div>
      ) : (
        <Link
          to={route ?? ''}
          className="full-w grid grid-cols-[max-content,1fr] items-center gap-4 p-2 pl-4"
        >
          <span className="grid grid-cols-[max-content,3fr] items-center gap-4">
            <span className="mr-2 w-6">{icon}</span>
            <Typography variant="body1" className="text-md">
              {name}
            </Typography>
          </span>
        </Link>
      )}
    </Component>
  );
}
