import { Button, InfoIcon, Typography } from '@pebl/ui';
import { Link } from 'react-router-dom';

import RoutePathConstants from '@/entrypoints/guest-website/routing/route-path-constants';
import { TranslationComponentProps } from '@/types/translation';

type CookiePolicyOverlayViewProps = {
  loading?: boolean;
  showOverlay?: boolean;
  cookiesAllowed?: boolean;
  setCookiesAllowed?: (cookiesAllowed: boolean) => void;
} & TranslationComponentProps;

export function CookiePolicyOverlayView({
  setCookiesAllowed,
  showOverlay,
  t,
}: CookiePolicyOverlayViewProps) {
  if (!showOverlay) {
    return false;
  }

  return (
    <div
      className={`qng-cookie-policy-overlay-wrapper fixed inset-0 z-40 bg-slate-600/50`}
    >
      <div className="qng-cookie-policy-card absolute inset-x-0 bottom-0">
        <div
          className={`
            qng-cookie-policy-overlay flex w-full items-center bg-primary p-4
            text-primary-foreground
          `}
        >
          <div className="grid grow grid-flow-row">
            <Typography variant="body1">
              {t('cookie_policy.consent')}
            </Typography>
            <Link
              to={RoutePathConstants.COOKIE_POLICY.link()}
              onClick={() => setCookiesAllowed?.(false)}
            >
              <Typography variant="caption" className="flex items-center">
                <InfoIcon className="mr-2" />
                {t('cookie_policy.link')}
              </Typography>
            </Link>
          </div>
          <div className="flex gap-2">
            <Button onClick={() => setCookiesAllowed?.(true)}>
              {t('cookie_policy.accept_button')}
            </Button>
            <Button onClick={() => setCookiesAllowed?.(false)}>
              {t('cookie_policy.reject_button')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
