import clsx from 'clsx';

/**
 * Represents a horizontal line divider.
 *
 * TODO: Implement a vertical divider if we require it and ues
 * the `orientation` prop to switch between horizontal and vertical.
 */
export function Divider({ className }: { className?: string }) {
  return <div className={clsx('qng-divider', 'my-4 border-b', className)} />;
}
