/* eslint-disable react-refresh/only-export-components */

import React from 'react';
import { RouteObject } from 'react-router-dom';

import RoutePathConstants from './route-path-constants';
import { AccountRoute } from './routes/account';
import { ActionRoute } from './routes/action';
import { AttractionDetailsRoute } from './routes/attraction-details';
import { BasketRoute } from './routes/basket';
import { ChangeLanguageRoute } from './routes/change-language';
import { CookiePolicyRoute } from './routes/cookie-policy';
import { FlowOnboardingMembershipRoute } from './routes/flow-onboarding-membership';
import { FlowOnboardingTicketRoute } from './routes/flow-onboarding-ticket';
import { HomeRoute } from './routes/home';
import { ShopRoute } from './routes/shop';
import { SignInRoute } from './routes/sign-in';
import { SignOutRoute } from './routes/sign-out';
import { TagRoute } from './routes/tag';

const Developer = React.lazy(
  () => import('@/features/developer/components/developer'),
);

const routes: RouteObject[] = [
  {
    path: RoutePathConstants.HOME.key,
    element: <HomeRoute />,
  },
  {
    path: RoutePathConstants.ACCOUNT.key,
    element: <AccountRoute />,
  },
  {
    path: RoutePathConstants.ATTRACTIONS.key,
    element: <HomeRoute />,
  },
  {
    path: RoutePathConstants.ATTRACTION_DETAILS.key,
    element: <AttractionDetailsRoute />,
  },
  {
    path: RoutePathConstants.TAG.key,
    element: <TagRoute />,
  },
  {
    path: RoutePathConstants.ACTION.key,
    element: <ActionRoute />,
  },
  {
    path: RoutePathConstants.CHANGE_LANGUAGE.key,
    element: <ChangeLanguageRoute />,
  },
  {
    path: RoutePathConstants.COOKIE_POLICY.key,
    element: <CookiePolicyRoute />,
  },
  {
    path: RoutePathConstants.SIGN_IN.key,
    element: <SignInRoute />,
  },
  {
    path: RoutePathConstants.SIGN_OUT.key,
    element: <SignOutRoute />,
  },
  {
    path: RoutePathConstants.SHOP.key,
    element: <ShopRoute />,
  },
  {
    path: RoutePathConstants.FLOW_ONBOARDING_TICKET.key,
    element: <FlowOnboardingTicketRoute />,
  },
  {
    path: RoutePathConstants.FLOW_ONBOARDING_MEMBERSHIP.key,
    element: <FlowOnboardingMembershipRoute />,
  },
  {
    path: RoutePathConstants.BASKET.key,
    element: <BasketRoute />,
  },
];

if (!import.meta.env.VITE_IS_RELEASE) {
  routes.push({
    path: RoutePathConstants.DEVELOPER.key,
    element: <Developer />,
  });
}

export default routes;

export * from './route-path-constants';
