import { ErrorIcon, InfoIcon } from '@pebl/ui';
import { AnimatePresence } from 'framer-motion';
import { useCallback } from 'react';

import { removeNotification, useQngDataStore } from '@/stores/qng-data-store';
import { TranslationComponentProps } from '@/types/translation';

import { SiteBannerView } from './site-banner-view';

type SiteBannerDataProps = {} & TranslationComponentProps;

export function SiteBannerData({ t }: SiteBannerDataProps) {
  const notifications = useQngDataStore((state) => state.notifications);

  const notificationCount = notifications.length;

  const errorNotifications = notifications.filter((n) => n.type === 'error');
  const informationalNotifications = notifications.filter(
    (n) => n.type === 'informational',
  );

  const latestNotification =
    errorNotifications.length > 0
      ? errorNotifications[errorNotifications.length - 1]
      : informationalNotifications.length === 0
        ? undefined
        : informationalNotifications[informationalNotifications.length - 1];

  const handleClose = useCallback(() => {
    if (!latestNotification?.id) {
      return;
    }

    removeNotification(latestNotification.id);
  }, [latestNotification?.id]);

  return (
    <AnimatePresence>
      {notificationCount > 0 && (
        <SiteBannerView
          t={t}
          message={latestNotification?.message}
          icon={
            latestNotification?.type === 'error' ? <ErrorIcon /> : <InfoIcon />
          }
          closeable
          onClose={handleClose}
          variant={latestNotification?.type === 'error' ? 'error' : 'default'}
        />
      )}
    </AnimatePresence>
  );
}
