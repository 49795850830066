import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // 1 minute
      gcTime: 300000, // 5 minutes
      retry: (failureCount) => {
        // TODO: Bring over the existing retry policy
        return failureCount < 3;
      },
      refetchInterval: (data) => {
        if (
          data &&
          'expires_in_secs' in data &&
          typeof data.expires_in_secs === 'number'
        ) {
          // Convert the API defined expires in secs to milliseconds
          const expiresInMillis = data.expires_in_secs * 1000;
          return expiresInMillis;
        }

        // TODO: Possible rethink how this works, this is _mostly_ a copy paste of the old code
        const defaultRefetchIntervalMilliseconds = Number(
          import.meta.env.VITE_DEFAULT_REFETCH_INTERVAL_MILLISECONDS,
        );
        return (
          (isNaN(defaultRefetchIntervalMilliseconds)
            ? undefined
            : defaultRefetchIntervalMilliseconds) ?? 60000
        );
      },
    },
    mutations: {},
  },
});
