import { useEffect } from 'react';

import useConfigThemePath from '@/hooks/use-config-theme-path';
import log from '@/utils/logging';

import i18n from './config';
import { loadThemeLocalisation } from './runtime-loader';

type RuntimeLocalisationProps = {
  /**
   * The filename of the translation file to load from the theme repository.
   * @default 'translation.json'
   */
  translationFileName?: string;
  localStorageKey?: string;
  overrideTheme?: string;
};

export function RuntimeLocalisation({
  localStorageKey = 'qng-theme-override',
  overrideTheme,
  translationFileName = 'translation.json',
}: RuntimeLocalisationProps) {
  const theme = useConfigThemePath(localStorageKey, overrideTheme);

  useEffect(() => {
    if (!theme) {
      return;
    }

    log.debug('Adding runtime localisation');

    /**
     * I'm not sure this is really *actually* a problem but we only handle
     * the concept of adding translations in from the theme. If a theme somehow
     * changed after already loading then it would just get the new one and merge that in
     * rather than removing the old ones or anything, but I don't think that's even really
     * a thing we need to worry about for now.
     */

    loadThemeLocalisation({
      prefix: 'themes/',
      translationFilePath: `${theme}${translationFileName}`,
    }).then((data) => {
      if (!data) {
        return;
      }

      for (const [language, resources] of Object.entries(data ?? {})) {
        i18n.addResourceBundle(language, 'translation', resources, true, true);
      }
    });
  }, [theme, translationFileName]);

  return <div />;
}
