import { useCookies } from 'react-cookie';

import { TranslationComponentProps } from '@/types/translation';

import { CookiePolicyOverlayView } from './cookie-policy-overlay-view';

// TODO: Move this to a shared location
export const QNG_COOKIE_NAME = 'qng-cookie-policy';

type CookiePolicyOverlayDataProps = {
  persistCookieName?: string;
} & TranslationComponentProps;

export function CookiePolicyOverlayData({
  persistCookieName = QNG_COOKIE_NAME,
  t,
}: CookiePolicyOverlayDataProps) {
  const [cookies, setCookie] = useCookies([persistCookieName], {
    doNotParse: true,
  });

  const cookieSet = cookies[persistCookieName] !== undefined;
  const cookiesAllowed = cookies[persistCookieName] === 'true';

  return (
    <CookiePolicyOverlayView
      showOverlay={!cookieSet}
      cookiesAllowed={cookiesAllowed}
      setCookiesAllowed={(val) => setCookie(persistCookieName, val)}
      t={t}
    />
  );
}
