/**
 * Shared function to generate any common headers and reduce
 * duplication of code such as omitting an undefined authToken
 * from all the defined API calls.
 *
 * This should simple generate the Key-Value pairs of headers
 * that are common to all API calls, based on options provided.
 *
 * An example use case is omitting the Authorization header if
 * the authToken is undefined.
 *
 * @param opts - Options object
 * @returns A Record of headers to be used in an axios request
 */
export function getCommonHeaders({
  authToken,
}: {
  authToken?: string | null | undefined;
}): Record<string, string> {
  const headers: Record<string, string> = {};

  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  return headers;
}
