import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import {
  GlobalNotification,
  GlobalNotificationType,
} from '@/types/notifications';
import { Guid } from '@/types/utility-types';

/**
 * NOTES:
 * - I have setup the devtools middleware which means we can _mostly_ use the
 *   Redux DevTools Extension to inspect the store and actions in Chrome which
 *   is VERY useful and something I would recommend. I was concerned not using
 *   Redux and would loose us this functionality which has been crucial in other
 *   projects to reason about state and action changes AND time-travel
 *   debugging.
 *
 * - If we start wanting to improve performance then one option _may_ be to
 *   memoize the selectors for the store - https://github.com/pmndrs/zustand/discussions/387
 *   (https://github.com/reduxjs/reselect - is one option)
 */

interface QngDataState {
  /**
   * -------------- Authentication --------------
   */

  /**
   * The current users auth token (could be anonymous I.E. not signed in
   * and provided by the API to use). This gets used in all API requests
   * to authenticate the user.
   */
  authToken: string | undefined;
  /**
   * The API provides anonymous tokens for users who are not signed in.
   * This is so that functionality like the basket can still work for
   * users who are not signed in.
   * This flag is used to determine if the token is an anonymous token otherwise
   * we would always consider a user "signed in" as there would ALWAYS be an
   * authToken set.
   */
  isTokenAnonymous: boolean;

  /**
   * !WARNING/NOTE: This will soon become deprecated / removed on the API
   *
   * API Action responses can contain a `storedResponseData` object which
   * can contain arbitrary data that we need to store in the global store
   * (and ultimately persist to local storage).
   * We don't know what this data will be ahead of time.
   */
  storedResponseData: Record<string, unknown>;

  /**
   * When the onboarding overlay was last dismissed by the user.
   * This is a unix/Date timestamp.
   * We use this to determine if we should show the onboarding overlay
   * again or not.
   */
  onboardingOverlayDismissedTimestamp: number;

  /**
   * ---------------- Notifications ----------------
   */
  notifications: GlobalNotification[];
}

/* ----------------------------------------------------------------------- */
//                             QNG DATA STORE
/* ----------------------------------------------------------------------- */

export const useQngDataStore = create<QngDataState>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      immer((_set) => ({
        authToken: undefined,
        isTokenAnonymous: false,
        storedResponseData: {},
        onboardingOverlayDismissedTimestamp: 0,
        notifications: [],
      })),
      {
        name: import.meta.env.VITE_DATA_STORE_NAME_PREFIX,
        // The default storage is localStorage which is fine for now
        // storage: createJSONStorage(() => sessionStorage)
        partialize: (state) => ({
          // Only selectively persist specific parts of the store
          // Make sure we add anything we want to persist here
          authToken: state.authToken,
          isTokenAnonymous: state.isTokenAnonymous,
          storedResponseData: state.storedResponseData,
          onboardingOverlayDismissedTimestamp:
            state.onboardingOverlayDismissedTimestamp,
          /**
           * TODO: do ACTUALLY want to persist notifications in Local storage ?
           * Currently this only persists informational ones (errors are transient).
           * But in reality I would think we would want to "recalc" the notifications
           * on a fresh site load ?
           */
          notifications: state.notifications.filter(
            (x) => x.type === 'informational',
          ),
        }),
        // onRehydrateStorage: (state) => {
        //   loglevel.debug("Rehydrated state", state);
        // },

        // NOTE: If we want to introduce breaking changes to the data store
        //       that may affect users (unlikely at this stage), then we should
        // look to use the version property and migrate function to handle the
        // migration of data from one version to another.
        // https://docs.pmnd.rs/zustand/integrations/persisting-store-data#version
      },
    ),
  ),
);
/**
 * Actions
 * - We don't co-locate the actions IN the store for the benefits outlined here
 *  - https://docs.pmnd.rs/zustand/guides/practice-with-no-store-actions
 *
 * Essentially by not putting them IN the store it means we don't necessarily
 * have to import the store/hook if it's not needed in a place we may want to
 * fire an action.
 */

/**
 * Set the Auth Token (and if it's anonymous) in the global store.
 * @param {Object} data - The new auth token to set
 * @param {string} data.authToken - The new auth token to set (or undefined to un-set it)
 * @param {boolean} data.isTokenAnonymous - Is the token anonymous (I.E. not signed in and provided by the API)
 */
export function setAuthToken({
  authToken,
  isTokenAnonymous,
}: {
  authToken: string | undefined;
  isTokenAnonymous: boolean;
}) {
  useQngDataStore.setState(
    () => ({ authToken, isTokenAnonymous }),
    false,
    'setAuthToken',
  );
}

export function setStoredResponseDataItem(key: string, value: unknown) {
  useQngDataStore.setState(
    (state) => {
      state.storedResponseData[key] = value;
    },
    false,
    'setStoredResponseDataItem',
  );
}

export function removeStoredResponseDataItem(key: string) {
  useQngDataStore.setState(
    (state) => {
      delete state.storedResponseData[key];
    },
    false,
    'removeStoredResponseDataItem',
  );
}

/**
 * Sets the timestamp when the onboarding overlay was last dismissed.
 * If you pass nothing/undefined then it will set the timestamp to the current time.
 *
 * @param timestamp - The timestamp to set (or undefined to set to the current time)
 */
export function setOnboardingOverlayDismissedTimestamp(timestamp?: number) {
  useQngDataStore.setState(
    (state) => {
      state.onboardingOverlayDismissedTimestamp = timestamp ?? Date.now();
    },
    false,
    'setOnboardingOverlayDismissedTimestamp',
  );
}

/**
 * Add a new notification to the notification stack.
 *
 * @param notification
 * @returns The ID of the new notification
 */
export function addNotification(
  notification: Omit<GlobalNotification, 'id' | 'addedAtTimestamp'>,
): Guid {
  const newNotificationId = uuidv4();

  useQngDataStore.setState(
    (state) => {
      // If 'context' is set, remove any existing entries matching that context
      const newContext = notification.context;
      if (newContext) {
        state.notifications = state.notifications.filter(
          (n) => n.context !== newContext,
        );
      }

      state.notifications.push({
        ...notification,
        id: newNotificationId,
        addedAtTimestamp: Date.now(),
      });
    },
    false,
    'addNotification',
  );

  return newNotificationId;
}

/**
 * Remove a notification from the notification stack.
 *
 * @param notification
 */
export function removeNotification(id: Guid) {
  useQngDataStore.setState(
    (state) => {
      state.notifications = state.notifications.filter((n) => n.id !== id);
    },
    false,
    'removeNotification',
  );
}

/**
 * Clear all notifications from the notification stack.
 */
export function clearNotifications() {
  useQngDataStore.setState(
    (state) => {
      state.notifications = [];
    },
    false,
    'clearNotifications',
  );
}

/**
 * Remove all notifications of a specific type from the notification stack.
 * (E.g. Useful to remove error notifications on route change)
 */
export function clearNotificationsOfType(type: GlobalNotificationType) {
  useQngDataStore.setState(
    (state) => {
      state.notifications = state.notifications.filter((n) => n.type !== type);
    },
    false,
    'clearNotificationsOfType',
  );
}
